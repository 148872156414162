import {withRouter} from "react-router-dom";
import {Dialog} from "primereact/dialog";
import React from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

const Subject = ({ subject }) =>
    <div className="rTableCell">
        <span id={"deviceCodeSpanId"}>{subject.name}</span>
        {subject.messages}
    </div>;

class ChatHistoryDialog extends React.Component {
    render() {
        let chatHistoryRenderObject = [];
        let RawChatChistory = ({ subjects }) => <div className="">
            <div className="argumentHeader">Liczba wątków: {subjects.length}</div>
            {subjects}
        </div>;
        let ChatChistoryDataTable = ({ subject }) =>
            //{"content":"Klient musi przygotować się do instalacji","senderFullName":"Arkadiusz Rzeszutek","timestamp":"2022-05-02T08:56:00"}
            <DataTable header={"Nazwa wątku: " + subject.name} style={{ textAlign: "center", display: "grid" }}
                       autoLayout={true} responsive={true} value={subject.messages} >
                <Column field="timestamp" header="Data i czas"/>
                <Column field="senderFullName" header="Autor"/>
                <Column field="content" header="Wiadomość"/>
            </DataTable>;
        if (this.props.chatHistory && this.props.chatHistory.subjects && this.props.chatHistory.subjects.length > 0) {
            this.props.chatHistory.subjects.forEach(subject => {
                chatHistoryRenderObject.push(<ChatChistoryDataTable subject={subject}/>);
            });
        }
        return (
            <Dialog header="Historia czatu" focusOnShow={true} closeOnEscape={true} footer="" visible={this.props.visible} className={'call-pt-dialog'} modal={true}
                    onHide={() => { if (this.props.onHide) this.props.onHide(); }}>
                {/*{this.props.chatHistory && this.props.chatHistory.subjects && this.props.chatHistory.subjects.length > 0 ? <ChatHistory chatHistory={chatHistoryRenderObject}/> : "Brak historii tego czatu"}*/}
                {this.props.chatHistory && this.props.chatHistory.subjects && this.props.chatHistory.subjects.length > 0 ? <RawChatChistory subjects={chatHistoryRenderObject} /> : "Brak historii tego czatu"}
            </Dialog>
        );
    }
}
export default withRouter(ChatHistoryDialog);