import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import React from 'react';
import { withRouter } from "react-router";
import { defaultDetail, defaultSummary } from "../../../config/constants";
import '../../../static/css/App.css';
import {Message} from "primereact/message";


class GlobalDictionary extends React.Component {

    state = {
        values: [],
        rows: 10, // Ilość wierszy na stronie
        rowKeysToSendUpdateToServer: new Set(),
        validationError: null
    }

    constructor(props) {
        super(props);

        this.verboseNameEditor = this.verboseNameEditor.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
    }

    componentDidMount() {
        this.getValues();
    }


    getValues() {

        this.props.fetchValues((data) => {
            this.setState({
                values: data,
            });
        },
        (error) => {
            this.setState({
                loading: false
            });
            console.error(`Error:${error}`);
        });
    }

    verboseNameEditor(props) {
        return this.inputTextEditor(props, 'verboseName');
    }

    inputTextEditor(props, field) {
        return <InputText type="text" value={props.rowData[field]}
            onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    onEditorValueChange(props, value) {

        const updatedValues = [...props.value];
        updatedValues[props.rowIndex][props.field] = value;
        const rowKeysToSendUpdateToServer = new Set(this.state.rowKeysToSendUpdateToServer);
        const id = updatedValues[props.rowIndex]["name"];
        rowKeysToSendUpdateToServer.add(id);
        this.setState({ values: updatedValues, rowKeysToSendUpdateToServer: rowKeysToSendUpdateToServer });
    }

    updateValue = (data) => {
        this.props.update(data, () => {
            const rowKeysToSendUpdateToServer = new Set(this.state.rowKeysToSendUpdateToServer);
            rowKeysToSendUpdateToServer.delete(data["name"]);
            this.setState({ rowKeysToSendUpdateToServer: rowKeysToSendUpdateToServer });
        }, () => {
            this.showError(defaultSummary, defaultDetail);
        });
    }

    showError = (summary, detail) => {
        this.messages.show({ severity: 'error', summary: summary, detail: detail });
    }

    actionTemplate(rowData) {
        return <div>
            <Button type="button" disabled={!this.state.rowKeysToSendUpdateToServer.has(rowData["name"])} icon="pi pi-save"
                className="p-button-success" onClick={() => {
                    this.updateValue(rowData);
                }}> </Button>
            {this.props.removeMode &&
                <Button type="button" style={{ "marginLeft": "10px" }} icon="pi pi-minus"
                    className="p-button-success" onClick={() => {
                        this.removeItem(rowData);
                    }}> </Button>
            }
        </div>;
    }

    render() {

        const header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}> {this.props.title}

            <Button icon="pi pi-plus" style={{ 'float': 'right', 'marginLeft': '10px' }}
                onClick={() => this.props.history.push("/dictionary/globalConfig/add")} />

            <Button
                icon="pi pi-refresh" style={{ 'float': 'right' }}
                onClick={() => this.getValues()} />


        </div>;

        return (

            <div className="content-section implementation">

                <div className="p-grid nogutter p-col-12">
                    <Messages ref={(el) => this.messages = el} />
                </div>
                <DataTable value={this.state.values} editable={true} header={header}>
                    <Column field="name" header="Nazwa klucza" style={{ textAlign: 'center' }} filter={true} sortable={true} />
                    <Column field="verboseName" header="Wartość wyświetlana" editor={this.verboseNameEditor}
                        sortable={true}
                        style={{ textAlign: 'center' }} filter={true} />
                    <Column body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                    {this.state.nameError &&
                    <div className="validation-msg o-value"><Message severity="error" text={this.state.nameError}/></div>}
                </DataTable>
                <br/>
            </div>


        );
    }


}

export default withRouter(GlobalDictionary);


