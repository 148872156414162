import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from "primereact/overlaypanel";
import React from 'react';
import { ACCOUNT_ROWS_PER_PAGE_SIZES, defaultDetail, defaultSummary } from '../../config/constants';
import AccountService from "../../services/AccountService";
import AccountFilterPanel from "../../shared/AccountFilterPanel";
import FilterSaver from '../../shared/FilterSaver';
import { defaultOnFilterChange, defaultOnFilterChangeWithCallback } from "../../shared/OrderFilterPanel";
import { AccountLoginTemplate, createParams, DateTimeTemplate } from "../../shared/OrderTableCommon";
import { filtersNotEmpty } from '../../shared/Utils';
import '../../static/css/App.css';
import _ from "lodash";
import { Messages } from 'primereact/messages';
import { groupBody } from './GroupBody';




class Accounts extends React.Component {

    state = {
        values: [],
        loading: false,
        first: 1,
        rows: 8, // Ilość wierszy na stronie
        totalRecords: 0,// Ilośc wszystkich elementów w bazie
        filters: {},
        area: {},
        sortBy: "updated,desc",
        filter: false,
        dialogVisible: false,
        selectedAccounts: [],

    }

    constructor(props) {
        super(props);
        this.onPage = this.onPage.bind(this);
    }


    componentDidMount() {
        this.setState({ filters: new FilterSaver().getFilters() }, () => {
            this.getValues(this.state.filters, this.state.sortBy, 1, 0);

            this.setState({
                filter: filtersNotEmpty(this.state.filters)
            });

        });
        this.scrollByTouch();
    }

    onPage(event) {
        this.setState({
            loading: true
        });
        const startIndex = event.first;
        const pageNumber = startIndex / event.rows + 1;

        this.setState({ pageNumber: pageNumber, startIndex: startIndex, rows: event.rows }, () => {
            this.getValues(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        });
    }

    getValues(filters, sortBy, pageNumber, startIndex) {

        const params = createParams(filters, sortBy);
        AccountService.getAll(params, pageNumber, this.state.rows, (page) => {

            this.setState({
                values: page.content,
                totalRecords: page.totalElements,
                loading: false,
                first: startIndex,
            });
        },
            (error) => {
                this.setState({
                    loading: false
                });
                console.error(`Error:${error}`);
            });
    }

    yesNoBody = (rowData, column) => {
        return (
            <span>{rowData[column.field] ? "tak" : "nie"}</span>

        );
    }

    reversedYesNoBody = (rowData, column) => {
        return (
            <span>{!rowData[column.field] ? "tak" : "nie"}</span>

        );
    }


    setSelectedAccounts = (value) => {
        this.setState({ selectedAccounts: value });
    }

    massBlock = () => {
        let selectedAccountsIds = this.state.selectedAccounts.map(a => a.id);
        AccountService.blockAccounts(selectedAccountsIds, () => {
            this.onSuccess();
        }, (error) => {
            this.onError(error);
        });
    }

    massUnblock = () => {
        let selectedAccountsIds = this.state.selectedAccounts.map(a => a.id);
        AccountService.unBlockAccounts(selectedAccountsIds, () => {
            this.onSuccess();
        }, (error) => {
            this.onError(error);
        });
    }


    isBlockButtonDisabled = () => {
        if (_.isEmpty(this.state.selectedAccounts)) {
            return true;
        }
        return !this.state.selectedAccounts.some(a => a.enabled);
    }

    isUnblockButtonDisabled = () => {
        if (_.isEmpty(this.state.selectedAccounts)) {
            return true;
        }
        return this.state.selectedAccounts.every(a => a.enabled);
    }

    onError(error) {
        console.error("error " + error);
        this.showError(defaultSummary, defaultDetail);
    }

    onSuccess() {
        this.setState({selectedAccounts:[]},()=>{
            this.getValues(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        })
    }


    showError = (summary, detail) => {
        this.messages.show({ severity: 'error', summary: summary, detail: detail });
    }


    render() {
        var header =
            <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
                Lista użytkowników
                <Button type="button" icon="pi pi-info" style={{ 'float': 'left', 'marginRight': '5px' }} onClick={(e) => this.op.toggle(e)} />
                <Button label="Zablokuj" type="button" icon="pi pi-times" style={{ 'float': 'left', 'marginRight': '5px' }}
                    tooltip={"Zablokuj zaznaczonych użytkowników"} className="p-button-danger"
                    disabled={this.isBlockButtonDisabled()}
                    onClick={this.massBlock} />
                <Button label="Odblokuj zablokowanych" type="button" icon="pi pi-check" style={{ 'float': 'left', 'marginRight': '5px' }}
                    tooltip={"Odblokuj zaznaczonych użytkowników"} className="p-button-success"
                    disabled={this.isUnblockButtonDisabled()}
                    onClick={this.massUnblock} />
                <Button icon="pi pi-search" style={{ 'float': 'right' }}
                    onClick={() => this.setState({ filter: !this.state.filter })} />
            </div>;

        return (
            <div className="content-section implementation">
                <Messages ref={(el) => this.messages = el} />
                {this.state.filter && <AccountFilterPanel
                    onFilterChange={defaultOnFilterChange(this)}
                    filters={this.state.filters}
                    onCleanButton={(filters) => {
                        defaultOnFilterChangeWithCallback(this, filters, () => {
                            this.getValues(this.state.filters, this.state.sortBy, 1, 0);
                        });
                    }}
                    onFilter={() => {
                        this.getValues(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    onSortFieldChange={(val, property) => {
                        this.setState({ [property]: val });
                    }}
                />}

                <DataTable value={this.state.values} editable={true}
                    ref={(el) => this.dt = el} responsive={false}
                    paginator={true} rows={this.state.rows}
                    totalRecords={this.state.totalRecords}
                    lazy={true} first={this.state.first} loading={this.state.loading} autoLayout={true}
                    header={header}
                    onPage={this.onPage} style={{ textAlign: "center" }}
                    paginatorTemplate="PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                    rowsPerPageOptions={ACCOUNT_ROWS_PER_PAGE_SIZES}
                    sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                    onSort={(e) => {
                        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder });
                        const sortBy = `${e.sortField},${e.sortOrder === 1 ? "asc" : "desc"}`;
                        this.setState({ sortBy: sortBy }, () => {
                            this.getValues(this.state.filters, this.state.sortBy, 1, 0);
                        });

                    }
                    }

                    selection={this.state.selectedAccounts}
                    onSelectionChange={e => this.setSelectedAccounts(e.value)}
                    dataKey="id"
                >
                    <Column field="multipleAssign" selectionMode="multiple" />
                    <Column field="login" header="Login" body={AccountLoginTemplate} sortable={true} />
                    <Column field="firstName" header="Imię" style={{ textAlign: 'center' }} sortable={true} />
                    <Column field="lastName" header="Nazwisko" style={{ textAlign: 'center' }} sortable={true} />
                    <Column field="installer" header="Serwisant" body={this.yesNoBody} style={{ textAlign: 'center' }}
                        sortable={true} />
                    <Column field="groups" header="Grupy" body={groupBody} style={{ textAlign: 'center' }} />
                    <Column field="installer.phoneNumber" header="msisdn" style={{ textAlign: 'center' }} />
                    <Column field="installer.company.verboseName" header="Firma" style={{ textAlign: 'center' }} />
                    <Column field="installer.area.verboseName" header="Strefa" style={{ textAlign: 'center' }} />
                    <Column field="enabled" header="Zablokowany" body={this.reversedYesNoBody} style={{ textAlign: 'center' }}
                        sortable={true} />
                    <Column field="created" body={DateTimeTemplate} header="Utworzono" />
                    <Column field="lastLoginDateTime" body={DateTimeTemplate} header="Ostatnie logowanie" />
                    <Column field="updated" body={DateTimeTemplate} header="Zmodyfikowano"
                        style={{ textAlign: "center" }} sortable={true} />
                    <Column field="passwordChangeRequired" body={this.yesNoBody} header="Wymagana zmiana hasła" />



                </DataTable>

                <OverlayPanel ref={(el) => this.op = el}>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <i className="pi pi-info-circle" style={{ 'fontSize': '3em', color: '#007ad9' }} />
                            <b>Informacje:</b>
                        </div>
                        <ul>
                            <li>Możesz przesuwać tabelę trzymając wciśnięte lewy oraz prawy przycisk myszy</li>
                            <li>Możesz przesuwać tabelę trzymając wciśnięty środkowy przycisk myszy</li>
                        </ul>

                    </div>
                </OverlayPanel>
            </div>


        );
    }

    scrollByTouch() {
        // https://codepen.io/thenutz/pen/VwYeYEE
        const slider = document.querySelector('#contentDivId .p-datatable-wrapper');
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener('mousedown', (e) => {

            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
            slider.style.cursor = "pointer";

            if (typeof e === 'object') {
                switch (e.button) {
                    case 0:
                        break;
                    case 1:
                        isDown = true;
                        break;
                    case 2:
                        break;
                    default:
                        console.error(`Unknown button code: ${e.button}`);
                }
            }

        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.style.cursor = "";
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) {
                return;
            }
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;

        });
    }


}

export default Accounts;

