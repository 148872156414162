import React from 'react';
import {
    DEFAULT_PAGE_SIZE,
    DEFAULT_SORT_BY_FOR_OPL_ORDER_HISTORY_QUEUE,
    HIDDEN_FIELDS_HISTORY_QUEUE,
    QueueType
} from "../../config/constants";
import DealerSupportService from "../../services/DealerSupportService";
import OrderDataTable from "../../shared/orderDataTable/OrderDataTable";
import OrderFilterLoadService from '../../shared/OrderFilterLoadService';
import OrderFilterPanel, { defaultOnFilterChange } from "../../shared/OrderFilterPanel";
import { createParams } from "../../shared/OrderTableCommon";
import { filtersNotEmptySkipModificationDateTimeFrom } from '../../shared/Utils';
import '../../static/css/App.css';

class DealerSupportHistory extends React.Component {

    state = {
        orders: [],
        loading: false,
        first: 1,
        rows: DEFAULT_PAGE_SIZE, // Ilość wierszy na stronie
        totalRecords: 0,// Ilośc wszystkich elementów w bazie
        filters: { },
        area: {},
        sortBy: DEFAULT_SORT_BY_FOR_OPL_ORDER_HISTORY_QUEUE,
        filter: false

    }

    constructor(props) {
        super(props);
        this.onPage = this.onPage.bind(this);
    }


    componentDidMount() {
        this.setState({ filters: new OrderFilterLoadService().getFilter(QueueType.HISTORY), loading: true},()=>{
            this.getOrders(this.state.filters, this.state.sortBy, 1, 0);

            this.setState({
                filter:filtersNotEmptySkipModificationDateTimeFrom(this.state.filters)
            });

        });

    }

    onPage(event) {
        this.setState({
            loading: true
        });
        const startIndex = event.first;
        const pageNumber = startIndex / event.rows + 1;
        this.setState({ pageNumber: pageNumber, startIndex: startIndex, rows: event.rows }, () => {
            this.getOrders(this.state.filters, this.state.sortBy, this.state.pageNumber, this.state.startIndex);
        });
    }



    getOrders(filters, sortBy, pageNumber, startIndex) {

        const params = createParams(filters, sortBy);
        DealerSupportService.getHistoryOrders(params, pageNumber, this.state.rows, (page) => {
            this.setState({
                orders: page.content,
                totalRecords: page.totalElements,
                loading: false,
                first: startIndex,
            });
        },
        (error) => {
            this.setState({
                loading: false
            });
            console.error(`Error:${error}`);
        });
    }


    render() {


        return (
            <div>
                {  <OrderFilterPanel
                    onFilterChange={defaultOnFilterChange(this)}
                    onCleanButton={(filters) => {
                        new OrderFilterLoadService().resetFilter((filters) => {
                            this.setState({filters:filters},()=>{
                                this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                            });
                        },QueueType.HISTORY);
                    }}
                    filters={this.state.filters} filter={this.state.filter}
                    onFilter={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    onSortFieldChange={(val, property) => {
                        this.setState({ [property]: val });
                    }}
                />}


                <OrderDataTable showAll={false} visible={"location"} hidden={HIDDEN_FIELDS_HISTORY_QUEUE} 
                    totalRecords={this.state.totalRecords} toogleFilter={() => this.setState({ filter: !this.state.filter })}
                    headerTitle={"Historia"} orders={this.state.orders} rows={this.state.rows}
                    first={this.state.first} loading={this.state.loading} onPage={this.onPage}
                    historyMode={true}
                    refreshTable={() => {
                        this.getOrders(this.state.filters, this.state.sortBy, 1, 0);
                    }}
                    openChatWithoutAssignment={true}
                    onSort={(sortBy) => {
                        this.setState({ sortBy: sortBy });
                        this.getOrders(this.state.filters, sortBy, 1, 0);
                    }} />

            </div>

        );
    }


}

export default DealerSupportHistory;

