import React, { Component } from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import packageJson from '../../../package.json';
import VersionService from "../../services/VersionService";
import PrivateRoute from "../../shared/PrivateRoute";
import '../../static/css/App.css';
import Account from "../account/Account";
import Accounts from "../account/Accounts";
import AddAccount from "../account/AddAccount";
import BotDsOrder from "../botPreview/ds/BotDsOrder";
import BotDsOrderHistoryList from "../botPreview/ds/BotDsOrderHistoryList";
import BotDsOrders from "../botPreview/ds/BotDsOrders";
import BotOkiPreviewHistoryList from "../botPreview/oki/BotOkiPreviewHistoryList";
import BotOkiPreviewOrder from "../botPreview/oki/BotOkiPreviewOrder";
import BotOkiPreviewOrders from "../botPreview/oki/BotOkiPreviewOrders";
import DealerSupportHistory from "../dealerSupport/DealerSupportHistory";
import DealerSupportOrder from "../dealerSupport/DealerSupportOrder";
import DealerSupportOrders from "../dealerSupport/DealerSupportOrders";
import AddArea from "../dict/AddArea";
import AddServiceOption from "../dict/AddServiceOption";
import Area from "../dict/Area";
import ContractStatus from "../dict/ContractStatus";
import DeviceModel from "../dict/DeviceModel";
import DeviceStatus from "../dict/DeviceStatus";
import AddGlobalProperties from "../dict/global/AddGlobalProperties";
import GlobalProperties from "../dict/global/GlobalProperties";
import OrderRejectionReason from "../dict/OrderRejectionReason";
import OrderStatus from "../dict/OrderStatus";
import OrderType from "../dict/OrderType";
import Service from "../dict/Service";
import ServiceOption from "../dict/ServiceOption";
import Technology from "../dict/Technology";
import TechnologyDla from "../dict/TechnologyDla";
import HistoryOrder from "../history/HistoryOrder";
import MonitoringAllOrders from "../monitoring/MonitoringAllOrders";
import MonitoringHistoryOrders from "../monitoring/MonitoringHistoryOrders";
import MonitoringLateOrders from '../monitoring/MonitoringLateOrders.js';
import MonitoringOrder from "../monitoring/MonitoringOrder";
import MonitoringTodayOrders from "../monitoring/MonitoringTodayOrders";
import MonitoringToTakeOrders from "../monitoring/MonitoringToTakeOrders";
import NjuHistory from "../nju/NjuHistory";
import NjuOrder from "../nju/NjuOrder";
import NjuOrders from "../nju/NjuOrders";
import AddNews from '../news/AddNews';
import EditNews from '../news/EditNews';
import NewsList from '../news/NewsList';
import OkiActivationHistory from "../okiActivation/OkiActivationHistory";
import OkiActivationOrder from "../okiActivation/OkiActivationOrder";
import OkiActivationOrders from "../okiActivation/OkiActivationOrders";
import OkiReplacementHistory from "../okiReplacement/OkiReplacementHistory";
import OkiReplacementOrder from "../okiReplacement/OkiReplacementOrder";
import OkiReplacementOrders from "../okiReplacement/OkiReplacementOrders";
import OrderPreview from "../orderPreview/OrderPreview";
import OrderPreviewList from "../orderPreview/OrderPreviewList";
import UserList from "../other/UserList";
import CacheSettings from "../settings/CacheSettings";
import WckuOrder from "../wcku/WckuOrder";
import WckuOrderHistoryList from "../wcku/WckuOrderHistoryList";
import WckuOrders from "../wcku/WckuOrders";
import Header from "./Header";
import Home from "./Home";
import LeftMenu from "./LeftMenu";
import NotFound from "./NotFound";
import AdminTestOpsPanel from "../admin/AdminTestOpsPanel";
import MonitoringSettings from "../monitoring/MonitoringSettings";
import AddRagResponse from "../dict/AddRagResponse";

class App extends Component {

    state = {
        menuVisible: true,
        apiVersion: null
    }

    componentDidMount() {
        VersionService.getVersion(
            (data) => {
                this.setState({ apiVersion: data.version });
            },
            (error) => {
                console.error("Error while obtaining API version: " + error);
            });
    }


    render() {
        return (
            <div className="p-grid p-col-12 p-nogutter">
                <Header onClickToggleMenu={this.onClickToggleMenu()
                } menuVisible={this.state.menuVisible} />

                <div className="p-grid p-col p-col-12" style={{"marginRight": "0px", "flex": "1"}}>
                    {this.state.menuVisible &&
                        <div className=" p-col p-col-2 mm  " >
                            <LeftMenu />
                        </div>
                    }
                    <div id="contentDivId" className="p-col p-col-10" style={{ "margin": "0px" }}>
                        <Switch>
                            {/*dealer support*/}
                            <PrivateRoute exact path="/dealerSupport/order" component={DealerSupportOrders} />
                            <PrivateRoute exact path="/dealerSupport/order/history" component={DealerSupportHistory} />
                            <PrivateRoute exact path="/dealerSupport/order/:id" component={DealerSupportOrder} />
                            <PrivateRoute exact path="/dealerSupport/order/:id/assign" component={DealerSupportOrder} />

                            <PrivateRoute exact path="/nju/order" component={NjuOrders} />
                            <PrivateRoute exact path="/nju/order/history" component={NjuHistory} />
                            <PrivateRoute exact path="/nju/order/:id" component={NjuOrder} />
                            <PrivateRoute exact path="/nju/order/:id/assign" component={NjuOrder} />


                            <PrivateRoute exact path="/dictionary/technology" component={Technology} />
                            <PrivateRoute exact path="/dictionary/technologyDla" component={TechnologyDla} />
                            <PrivateRoute exact path="/dictionary/service" component={Service} />
                            <PrivateRoute exact path="/dictionary/serviceOption" component={ServiceOption} />
                            <PrivateRoute exact path="/dictionary/serviceOption/add" component={AddServiceOption} />
                            <PrivateRoute exact path="/dictionary/orderType" component={OrderType} />
                            <PrivateRoute exact path="/dictionary/orderRejectionReason" component={OrderRejectionReason} />
                            <PrivateRoute exact path="/dictionary/area" component={Area} />
                            <PrivateRoute exact path="/dictionary/area/add" component={AddArea} />
                            <PrivateRoute exact path="/dictionary/contractStatus" component={ContractStatus} />
                            <PrivateRoute exact path="/dictionary/deviceStatus" component={DeviceStatus} />
                            <PrivateRoute exact path="/dictionary/orderStatus" component={OrderStatus} />
                            <PrivateRoute exact path="/dictionary/deviceModel" component={DeviceModel} />
                            <PrivateRoute exact path="/dictionary/globalConfig" component={GlobalProperties} />
                            <PrivateRoute exact path="/dictionary/globalConfig/add" component={AddGlobalProperties} />

                            {/*Podgląd zleceń */}
                            <PrivateRoute exact path="/orderPreview/order" component={OrderPreviewList} />
                            <PrivateRoute exact path="/orderPreview/order/:id" component={OrderPreview} />


                            {/*OkiReplacement*/}
                            <PrivateRoute exact path="/okiReplacement/order" component={OkiReplacementOrders} />
                            <PrivateRoute exact path="/okiReplacement/order/history" component={OkiReplacementHistory} />
                            <PrivateRoute exact path="/okiReplacement/order/:id" component={OkiReplacementOrder} />
                            <PrivateRoute exact path="/okiReplacement/order/:id/assign" component={OkiReplacementOrder} />

                            {/*OkiActivation*/}
                            <PrivateRoute exact path="/okiActivation/order" component={OkiActivationOrders} />
                            <PrivateRoute exact path="/okiActivation/order/history" component={OkiActivationHistory} />
                            <PrivateRoute exact path="/okiActivation/order/:id" component={OkiActivationOrder} />
                            <PrivateRoute exact path="/okiActivation/order/:id/assign" component={OkiActivationOrder} />

                            {/*wcku*/}
                            <PrivateRoute exact path="/wcku/order" component={WckuOrders} />
                            <PrivateRoute exact path="/wcku/order/history" component={WckuOrderHistoryList} />
                            <PrivateRoute exact path="/wcku/order/:id" component={WckuOrder} />

                            {/*oki - bot*/}
                            <PrivateRoute exact path="/botOkiPreview/order" component={BotOkiPreviewOrders} />
                            <PrivateRoute exact path="/botOkiPreview/order/history" component={BotOkiPreviewHistoryList} />
                            <PrivateRoute exact path="/botOkiPreview/order/:id" component={BotOkiPreviewOrder} />

                            {/*ds - bot*/}
                            <PrivateRoute exact path="/botDsPreview/order" component={BotDsOrders} />
                            <PrivateRoute exact path="/botDsPreview/order/history" component={BotDsOrderHistoryList} />
                            <PrivateRoute exact path="/botDsPreview/order/:id" component={BotDsOrder} />

                            {/*monitoring*/}
                            <PrivateRoute exact path="/monitoring/settings" component={MonitoringSettings} />
                            <PrivateRoute exact path="/monitoring/settings/ragResponse/add" component={AddRagResponse} />
                            <PrivateRoute exact path="/monitoring/order/all" component={MonitoringAllOrders} />
                            <PrivateRoute exact path="/monitoring/order/history" component={MonitoringHistoryOrders} />
                            <PrivateRoute exact path="/monitoring/order/toTake" component={MonitoringToTakeOrders} />
                            <PrivateRoute exact path="/monitoring/order/late" component={MonitoringLateOrders} />

                            <PrivateRoute exact path="/monitoring/order/today" component={MonitoringTodayOrders} />

                            <PrivateRoute exact path="/monitoring/order/:id" component={MonitoringOrder} />
                            <PrivateRoute exact path="/monitoring/order/:id/assign" component={MonitoringOrder} />

                            <PrivateRoute exact path="/history/order/:id" component={HistoryOrder} />

                            {/*Account*/}
                            <PrivateRoute exact path="/accounts" component={Accounts} />
                            <PrivateRoute exact path="/account/add" component={AddAccount} />
                            <PrivateRoute exact path="/account/:id" component={Account} />

                            {/*Admin*/}
                            <PrivateRoute exact path="/testOps" component={AdminTestOpsPanel}/>

                            {/*Settings*/}
                            <PrivateRoute exact path="/settings/cache" component={CacheSettings} />

                            <PrivateRoute exact path="/news" component={NewsList} />
                            <PrivateRoute exact path="/news/add" component={AddNews} />
                            <PrivateRoute exact path="/news/edit/:id" component={EditNews} />

                            <PrivateRoute path="/" component={Home} />
                            <Route path="/users" component={UserList} />
                            <Route component={NotFound} />
                        </Switch>
                    </div>
                </div>
                <div className="footer p-col-12">
                    <span className={"footerElement"}>Wersja panelu: <span className={"bold"}>{packageJson.version}</span></span>
                    <span className={"footerElement"}>Wersja API: <span className={"bold"}>{this.state.apiVersion}</span></span>
                    <span className={"footerElement"}>Copyright © {new Date().getFullYear()} <span className={"orange bold"}>Orange</span></span>
                </div>

            </div>
        );
    }

    onClickToggleMenu() {
        return () => {
            if (this.state.menuVisible) {
                document.getElementById("contentDivId").classList.remove('p-col-10');
                document.getElementById("contentDivId").classList.add('p-col-12');
            } else {
                document.getElementById("contentDivId").classList.remove('p-col-12');
                document.getElementById("contentDivId").classList.add('p-col-10');
            }
            this.setState({ menuVisible: !this.state.menuVisible });
        };
    }
}

export default App;
