import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import {Messages} from "primereact/messages";
import queryString from "query-string";
import React from 'react';
import {withRouter} from 'react-router-dom';
import {changeToInProgress, OplStatus} from "../../config/constants";
import CurrentAccountService from "../../services/CurrentAccountService";
import ErrorService from "../../services/ErrorService";
import MonitoringService from "../../services/MonitoringService";
import AssignPanel from "../../shared/AssignPanel";
import '../../static/css/App.css';
import MonitoringOperationUtil from "./MonitoringOperationUtil.js";
import DealerSupportRagResponseDiv from "../../shared/DealerSupportRagResponseDiv";
import {Message} from "primereact/message";
import {InputText} from "primereact/inputtext";

class MonitoringOperation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOperation: "",
            comment: null,
            orderRejectionReason: null,
            dealerSupportRagResponse: null,
            idsNumber: ""
        };
    }

    showError = (summary, detail) => {
        this.messages.show({severity: 'error', summary: summary, detail: detail});
    }

    showInfo = (summary, detail) => {
        this.messages.show({severity: 'info', summary: summary, detail: detail});
    }


    invokeOperation = (orderId) => {
        this.setState({disabled: true}, function () {
            let data = {};
            data = {
                comment: "Odpowiedź: " + this.state.dealerSupportRagResponse["name"] + "\nKomentarz: " + (this.state.comment ? this.state.comment : ""),
                idsNumber: this.state.idsNumber
            };
            if (this.props.order.status.name === 'PT_LATE') {
                this.resumeOrderAndForwardToInstallerLate(orderId, data);
            } else {
                this.resumeOrderAndForwardToInstaller(orderId, data);
            }
        });
    }


    redirectOnSuccess() {
        // this.props.history.push("/dealerSupport/order");
        this.props.history.goBack();
    }

    resumeOrderAndForwardToInstaller(orderId, data) {
        MonitoringService.resumeOrderAndForwardToInstaller(orderId, data, () => {
            this.redirectOnSuccess();
            this.setState({disabled: false});
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });
        });
    }

    resumeOrderAndForwardToInstallerLate(orderId, data) {
        MonitoringService.resumeOrderAndForwardToInstallerLate(orderId, data, () => {
            this.redirectOnSuccess();
            this.setState({disabled: false});
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });
        });
    }

    discard(orderId, data) {
        MonitoringService.discard(orderId, data, () => {
            this.redirectOnSuccess();
            this.setState({disabled: false});
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });
        });
    }

    changeToInProgress(orderId) {
        MonitoringService.changeToInProgress(orderId, () => {

            if (this.props.refreshOrder) {
                this.props.refreshOrder();
                this.showInfo("Zmieniono status DS na \"W trakcie\"", "");
                this.setState({disabled: false});
            }
        }, (error) => {
            ErrorService.getErrorMsgToShow(error, (summary, detail) => {
                this.showError(summary, detail);
                this.setState({disabled: false});
            });
        });
    }

    isOPLUserAssingedToOrder() {
        return this.props.order.assignedOplUser;
    }

    isCurrentOPLUserAssignedToOrder() {
        if (this.props.order.assignedOplUser) {
            let login = this.props.order.assignedOplUser.login;
            return CurrentAccountService.getLogin() === login;
        }

    }

    isAnyOperationAvailable() {
        const isAnyOperationAvailableVar = MonitoringOperationUtil.isAnyOperationAvailable(this.props.order);

        return isAnyOperationAvailableVar || this.isOplStatusMode() && !this.isOplStatusEqualInProgress();
    }

    isOplStatusMode() {
        return queryString.parse(this.props.location.search)["oplStatus"] === 'true';
    }

    isOplStatusEqualInProgress() {
        return this.props.order && this.props.order.oplStatus && this.props.order.oplStatus === OplStatus.IN_PROGRESS;
    }

    idsClasses() {
        return (!this.isIdsEmpty() && this.isIdsValid()) ? "p-d-block" : "p-d-block p-invalid";
    }

    isIdsValid() {
        let validIds = new RegExp('^IDS\\d{15}$');
        return this.state.idsNumber && validIds.test(this.state.idsNumber);
    }

    isIdsEmpty() {
        return !(this.state.idsNumber && this.state.idsNumber.length > 0);
    }

    getOperations() {
        const operations = MonitoringOperationUtil.getOperations(this.props.order);
        if (this.isOplStatusMode() && !this.isOplStatusEqualInProgress()) {
            operations.push(changeToInProgress);
        }
        return operations;
    }

    render() {
        const {match} = this.props;
        return (
            <>
                {MonitoringOperationUtil.showMonitoringOperation(this.props.order) ?
                    <div className="p-grid nogutter p-col-4 p-component p-align-start p-col-align-start">
                        <>
                            <Messages ref={(el) => this.messages = el}/>
                            <AssignPanel assign={(orderId, onSuccess, onError) => {
                                if (this.props.order.status.name === 'PT_LATE') {
                                    MonitoringService.assingOplUserLate(orderId, onSuccess, onError)
                                } else {
                                    MonitoringService.assingOplUser(orderId, onSuccess, onError)
                                }
                            }}
                                         unassign={(orderId, onSuccess, onError) => {
                                                 MonitoringService.unassingOplUser(orderId, onSuccess, onError)
                                         }}
                                         refreshOrder={this.props.refreshOrder} order={this.props.order}
                                         shouldAssign={this.props.shouldAssign}/>
                            {this.isCurrentOPLUserAssignedToOrder() && this.isAnyOperationAvailable() ?
                                <>
                                    <div className="p-grid nogutter p-col-12">
                                        <label className="o-label" htmlFor="in">Wyślij odpowiedź do serwisanta:</label>
                                        <Message className="justify-content-start" severity="info"
                                                 text="Wybór statusu 'Odrzucone' został przeniesiony do słownika odpowiedzi do serwisanta Partnera Technicznego."/>
                                    </div>
                                    <DealerSupportRagResponseDiv
                                        onChange={(event) => {
                                            this.setState({dealerSupportRagResponse: event.value});
                                        }
                                        }/>
                                    <div className="p-grid nogutter p-col-12">
                                        <label className="p-col-3 o-label">Komentarz:</label>
                                        <span className="o-value">
                                                <InputTextarea rows={4} cols={30} autoResize={true}
                                                               onChange={(e) => this.setState({comment: e.target.value})}/>
                                            </span>
                                    </div>
                                    <div className="p-grid p-col-12 p-field">
                                        <label className="p-col-3 o-label p-d-block" htmlFor="oldPassword">Numer
                                            IDS:</label>
                                        <div className="p-col-6 p-field">
                                            <InputText placeholder="IDSXXXXXXXXXXXXXXX" value={this.state.idsNumber}
                                                       onInput={(e) => this.setState({idsNumber: e.target.value})}
                                                       invalid className={this.idsClasses()}/>
                                            <div>
                                                {!this.isIdsEmpty() && !this.isIdsValid() ?
                                                    <small className="p-d-block p-error">
                                                        Prawidłowy format numeru to przedrostek IDS oraz 15 cyfr.
                                                    </small> : ""
                                                }
                                                {!this.isIdsEmpty("tooltip empty") ? "" :
                                                    <small className="p-d-block p-error">
                                                        Uzupełnienie tego pola jest wymagane.
                                                    </small>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid nogutter p-col-12 p-justify-start">
                                        <span className="p-col-3 o-label"/>
                                        <Button
                                            disabled={this.state.disabled || !this.isIdsValid() || this.isIdsEmpty()}
                                            style={{"marginLeft": "10px", "marginTop": "5px"}}
                                            className="o-value" onClick={() => {
                                            this.invokeOperation(match.params.id);
                                        }} label="Przekaż"/>
                                    </div>
                                </>
                                : null}
                        </>
                    </div>
                    : null}
            </>
        );
    }
}

export default withRouter(MonitoringOperation);

